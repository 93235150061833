import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page-mdx.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "terms--conditions",
      "style": {
        "position": "relative"
      }
    }}>{`Terms & Conditions`}</h1>
    <p>{`Notifee is a website operated by Invertase Limited ('Invertase', 'we' or 'us). Invertase is registered in
England and Wales under company number 10839473 and its registered office is at 130 Old Street, London, EC1V 9BD,
England. Our VAT number is 271 6371 03.`}</p>
    <h2 {...{
      "id": "agreement-between-you-and-notifee",
      "style": {
        "position": "relative"
      }
    }}>{`Agreement between you and Notifee`}</h2>
    <p>{`These general Terms and Conditions ("Terms") are an agreement ("Agreement") between you and Notifee and
cover your use of the information, software, products and services made available through notifee.app
(the "Website"). By using the Website, you agree to be bound by these Terms, as well as our Privacy Policy.`}</p>
    <p>{`You are responsible for making all arrangements necessary for you to have access to the Website and for ensuring any
contact details you provide us with are correct and up to date. You are also responsible for ensuring that all persons
who access the Website through your internet connection are aware of these Terms and that they comply with them.`}</p>
    <p>{`The Website is offered to you conditional upon your acceptance of these Terms and any notices contained in these Terms
and the Website itself. Please read these Terms carefully before you start using the Website. By using the Website,
you agree to these Terms.`}</p>
    <p>{`Please note that the statements set forth under the headings "More simply put" are provided as a courtesy solely for
your convenience and are not legally binding or otherwise intended to modify these Terms in any way.`}</p>
    <p>{`You must be the legal age of majority in your country of residence in order to use the Website. In no event is use of
the Website permitted by those under the age of 18.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      